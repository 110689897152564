
import * as Components from "./Cravestudiocity"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "cravestudiocity"
}

