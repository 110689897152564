import React from 'react'
import {Link} from 'react-router-dom'
import './Form.scss'

export default function () {
  return(
    <div className="form-wrapper-contact">
      <form name="join-team" method="POST" data-netlify="true" enctype='multipart/form-data'>
        <h2>Join Our Team</h2>

        <p>Upload your resume and we will get back to you as soon as possible.</p>
        {/* <p>Sign up to be notified when we open</p> */}


        <input type="hidden" name="form-name"  value="join-team" />
        <p>
          <label><span className="label-text">Name:</span> <input type="text" name="name" /></label>
        </p>
        <p>
          <label><span className="label-text">Email:</span> <input type="email" name="email" /></label>
        </p>
        <p>
          <label><span className="label-text">Phone:</span> <input type="telephone" name="phone" /></label>
        </p>
        <p>
          <label><span className="label-text">Message:</span> <textarea type="text" name="message" /></label>
        </p>
        <p>
          <label><span className="label-text">File Attachement (pdf, doc, docx):</span> 
            <br/>
            <input type="file" id="file" name="file" multiple /></label>
        </p>
        {/* <p>
          <label>Optional Zipcode: (to help us plan our locations)
          <br/><input type="zipcode" name="zipcode" /></label>
        </p> */}
        {/* <p>
          <label>Message: <textarea name="message"></textarea></label>
        </p> */}
        <p>
          <button type="submit">Submit</button>
        </p>
      </form>
    </div>
  )
}
