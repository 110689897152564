import React from 'react'
import {Link} from 'react-router-dom'
import './Form.scss'

export default function () {
  return(
    <div className="form-wrapper-contact">
      <form name="cravestudio" method="POST" data-netlify="true">
      <h2>Crave Cafe Promotions</h2>

      <p>Receive news and exclusive discount</p>

        {/* <p>Voucher</p> */}
        {/* <p>Sign up to be notified when we open</p> */}


        <input type="hidden" name="form-name" value="cravestudio" />
        <p>
          <label>Your Name: <input type="text" name="name" /></label>
        </p>
        <p>
          <label>Your Email: <input type="email" name="email" /></label>
        </p>
        {/* <p>
          <label>Optional Zipcode: (to help us plan our locations)
          <br/><input type="zipcode" name="zipcode" /></label>
        </p> */}
        {/* <p>
          <label>Message: <textarea name="message"></textarea></label>
        </p> */}
        <p>
          <button type="submit">Submit</button>
        </p>
      </form>
    </div>
  )
}
